<mat-card class="custom-card title-card" id="general-title-container"
          [ngClass]="{'no-margin' : !!noMargin}">
  <mat-card-content class="title-card-content flexbox align-center justify-spaced">
    <div class="title flex-shrink-0">
      @if (subTitle) {
        <h1 class="smaller">{{ title }}</h1>
        <h3>{{ subTitle }}</h3>
      } @else {
        <h1>{{ title }}</h1>
      }
    </div>
    <!--    <div class="divider"></div>-->
    <div id="general-title-elements" class="w-100-percent flexbox justify-end small-gap">
      <ng-content></ng-content>
    </div>
  </mat-card-content>
</mat-card>
