import { Component, Inject, OnInit } from '@angular/core';
import { DocumentData, Project, TaskData } from '@eeule/eeule-shared/src/types';
import {
  BehaviorSubject,
  catchError,
  combineLatest,
  debounceTime,
  distinctUntilChanged, EMPTY,
  lastValueFrom,
  map,
  of,
  startWith,
  Subject,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs';
import { BaseComponent } from '../../../../core/components/base/base.component';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogActions,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';
import { SnackbarService } from '../../../../core/services/snackbar.service';
import { ProjectService } from '../../../../core/services/project.service';
import { DocumentService } from '../../../../core/services/document.service';
import { StorageService } from '../../../../core/services/storage.service';
import { TaskService } from '../../../../core/services/task.service';
import { handleBasicError } from '../../../../../util/error.helper';
import {
  UploadDocumentDialogComponent,
  UploadDocumentDialogConfig,
} from '../../../components/upload-document-dialog/upload-document-dialog.component';
import { MatCheckboxChange, MatCheckboxModule } from '@angular/material/checkbox';
import { AsyncPipe } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { SearchBarComponent } from '../../../../core/components/search-bar/search-bar.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TaskAttachment } from '../../../components/indicator-task-dialog/indicator-task-dialog.component';

export type AddTaskAttachmentDialog = {
  taskId: string,
  connectedAttachments: TaskAttachment[]
}

@Component({
  selector: 'eule-add-task-attachment-dialog',
  standalone: true,
  imports: [
    AsyncPipe,
    MatButtonModule,
    MatCheckboxModule,
    MatDialogActions,
    MatDialogContent,
    MatDialogTitle,
    MatIconModule,
    MatProgressSpinnerModule,
    SearchBarComponent,
    MatTooltipModule,
  ],
  templateUrl: './add-task-attachment-dialog.component.html',
  styleUrl: './add-task-attachment-dialog.component.scss',
})
export class AddTaskAttachmentDialogComponent extends BaseComponent implements OnInit {
  isLoading: boolean = false;
  isUpdating: boolean = false;
  taskAttachments$: BehaviorSubject<TaskAttachment[]> = new BehaviorSubject<TaskAttachment[]>([]);
  filteredAttachments: TaskAttachment[] = [];
  search$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  refetch$: Subject<void> = new Subject<void>();
  attachmentListIsDirty: boolean = false;
  currentTask: TaskData | null = null;

  constructor(@Inject(MAT_DIALOG_DATA) public dialogData: AddTaskAttachmentDialog,
              public dialogRef: MatDialogRef<AddTaskAttachmentDialogComponent>,
              private _dialog: MatDialog,
              private _snackBarService: SnackbarService,
              private _projectService: ProjectService,
              private _documentService: DocumentService,
              private _taskService: TaskService,
              private _storageService: StorageService) {
    super();
  }

  ngOnInit() {
    combineLatest([
      this.refetch$.pipe(startWith(null)),
      this._projectService.project$,
    ]).pipe(
      tap(() => {
        this.isLoading = true;
      }),
      /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
      switchMap(([_, project]) => {
        if (!project?.id) return combineLatest([of([]), of(null)]);
        return combineLatest([
          this._documentService.getLiveAllProjectDocumentsFromFirestore(project.id),
          this._taskService.getProjectTask(project.id, this.dialogData.taskId)
          ]);
      }),
      catchError(error => {
        this.isLoading = false;
        this._snackBarService.showErrorMessage('Fehler beim Abrufen der Projekt-Dokumente');
        throw new Error(error);
      }),
      takeUntil(this.stop$)
    ).subscribe(([projectAttachments, task]) => {
      try {
        if (!task) {
          this._snackBarService.showErrorMessage('Die zugeordnete Aufgabe konnte nicht abgerufen werden.');
          return handleBasicError('connected task could not be retrieved');
        }
        this.currentTask = task;
        this.taskAttachments$.next(projectAttachments.map(attachment => {
          return this.dialogData.connectedAttachments.find(o => o.id === attachment.id)
            ? { ...attachment, connected: true }
            : attachment;
        }));
        this.isLoading = false;
      } catch (error) {
        this._snackBarService.showErrorMessage('Fehler beim Abrufen der Projekt-Dokumente');
        handleBasicError(error);
      }
    });

    combineLatest([
      this.search$.pipe(
        debounceTime(200),
        startWith(null),
        distinctUntilChanged(),
      ),
      this.taskAttachments$.pipe(map(res => res.filter(o => o.name?.length))),
    ]).pipe(takeUntil(this.stop$))
      .subscribe(([phrase, attachments]) => {
        this.filteredAttachments = attachments.filter(attachment => {
          if (!phrase || phrase.length < 2) return true;
          return attachment.name && attachment.name?.toString().indexOf(phrase) !== -1
            || attachment.description && attachment.description?.toString().indexOf(phrase) !== -1
            || attachment.format && attachment.format?.toString().indexOf(phrase) !== -1;
        });
      });
  }

  onAttachmentSearch(searchTerm: string) {
    this.search$.next(searchTerm);
  }

  async onAttachmentKeyDown(event: KeyboardEvent, id: string) {
    if (event.key === 'Enter' || event.key === ' ') {
      await this.onOpenAttachment(id);
      event.preventDefault();
    }
  }

  async onOpenAttachment(id: string) {
    const project: Project | null = this._projectService.project$.value;
    if (!project) {
      return handleBasicError('Error while retrieving current project');
    }
    const downloadUrl: string = await lastValueFrom(this._storageService.getProjectDocumentDownloadUrl(project.id, id));
    window.open(downloadUrl, '_blank');
  }

  onNewDocument() {
    const dialogRef = this._dialog.open<UploadDocumentDialogComponent, UploadDocumentDialogConfig, DocumentData>(UploadDocumentDialogComponent, {
      width: '600px',
      maxWidth: '70vw',
      data: null,
    });

    dialogRef.afterClosed().subscribe((document: DocumentData | undefined) => {
      if (document) {
        this.isLoading = true;
        this._storageService.uploadProjectDocument(this._projectService.project$.value!.id, document).pipe(
          catchError(error => {
            this._snackBarService.showErrorMessage('Beim Hochladen der Datei ist ein Fehler aufgetreten');
            console.error(error);
            return EMPTY;
          }),
          takeUntil(this.stop$)
        ).subscribe(() => {
          this.refetch$.next();
        });
      }
    });
  }

  get connectedAttachments(): TaskAttachment[] {
    const connectedAttachments: TaskAttachment[] = this.taskAttachments$.value;
    return connectedAttachments.filter(o => o.connected);
  }

  onConnectTaskAttachment(ev: MatCheckboxChange, document: DocumentData) {
    try {
      this.attachmentListIsDirty = true;

        const taskAttachments: TaskAttachment[] = this.taskAttachments$.value;

        this.taskAttachments$.next([
          ...taskAttachments.map(attachment => {
            if (document.id === attachment.id) return {...attachment, connected: ev.checked }
            return attachment;
          })
        ]);
    } catch (error) {
      this.isUpdating = false;
      this._snackBarService.showErrorMessage('Beim Verknüpfen des Anhangs ist ein Fehler aufgetreten');
      handleBasicError(error);
    }
  }
}
