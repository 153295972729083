import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatCardModule } from '@angular/material/card';

@Component({
  selector: 'eule-general-title',
  standalone: true,
  imports: [CommonModule, MatCardModule],
  templateUrl: './general-title.component.html',
  styleUrl: './general-title.component.scss',
})
export class GeneralTitleComponent {
  @Input({ required: true }) title!: string;
  @Input() subTitle?: string;
  @Input() noMargin?: boolean;
}
