import { Timestamp } from 'firebase/firestore';

/**
 * Converts a timestamp, date, string, or number to a JavaScript `Date` object.
 *
 * @param {Timestamp | Date | string | number} [date] - The input date in various possible formats.
 * @returns {Date | null} The converted `Date` object, or `null` if the input is invalid.
 */
export function timeStampToDate(date?: Timestamp | Date | string | number): Date | null {
  if (!date) return null;

  if ((date as Timestamp)?.seconds !== undefined) {
    return (date as Timestamp).toDate();
  }

  if (isValidDate(date)) return date as Date;

  if ((typeof date === 'string' || typeof date === 'number') && isValidDate(new Date(date))) {
    return new Date(date);
  }

  return null;
}

/**
 * Checks if the given input is a valid `Date` object.
 *
 * @param {unknown} date - The input to check.
 * @returns {boolean} `true` if the input is a valid `Date` object, otherwise `false`.
 */
function isValidDate(date: unknown): boolean {
  return date instanceof Date && !isNaN(date.getTime());
}
