import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { convertNumber } from './conversion-helper';

export const germanNumberExp: RegExp = new RegExp("^-?(\\d{1,3}(\\.\\d{3})*|\\d+)(,\\d+)?$");

export const oneToOneHundredExp: RegExp = new RegExp("^(100|[1-9][0-9]?)$");

export const germanNumberWithLeadingPlusAndHyphenInBetween: RegExp
  = new RegExp("^[+]?(\\d+(,\\d*)?)(-([+]?(\\d+(,\\d*)?)))?$");

export function passwordMisMatchValidator(passwordToMatchControlName: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const password = control.value;
    const passwordToMatch = control.parent?.get(passwordToMatchControlName);

    if (!password || !passwordToMatch?.value) {
      return null;
    }

    if (!passwordToMatch?.value) {
      return { mismatch: true };
    }

    return passwordToMatch.value !== password ? { mismatch: true } : null;
  };
}

export function isNullOrUndefined(value: unknown): boolean {
  return value === null || value === undefined || typeof value === 'undefined';
}

export function maxFixedValueValidator(maxValue: number): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const controlValue: string | number = control.value;
    const valueToCheck: number | null = typeof controlValue === 'string'
      ? convertNumber(controlValue)
      : controlValue;

    if (valueToCheck !== null && valueToCheck > maxValue) {
      return { maxFixedValueExceeded: true };
    }

    return null;
  };
}
